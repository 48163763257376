import { type Loss, type Policy } from '../types/Entity';

/**
 * @description
 * Given a `policy`, returns the cumulative number of losses recorded.
 *
 * It iterates over `losses` field from all `perils` on the first `exposure`. It accumulated
 * losses from all perils, deduplicating by timestamp, and returns accumulated losses value.
 */
const totalLossesForPolicy = (policy: Policy): number => {
  const filteredLosses: Loss[] = [];

  policy.exposures[0].perils.forEach((peril) => {
    peril.losses.forEach((loss) => {
      const isDuplicateTimestamp = filteredLosses.find((fl: Loss) => {
        return fl.occurredAt === loss.occurredAt;
      });

      if (!isDuplicateTimestamp) {
        filteredLosses.push(loss);
      }
    });
  });

  return filteredLosses.map((l) => l.amount).reduce((acc, a) => acc + a, 0);
};

export default {
  totalLossesForPolicy,
};
