//used in client
import { isPeril, isPerilOperator, Peril, PerilOperator } from './Peril.types';
import { isClaim, Claim } from './Claim.types';
import {
  stringValidator,
  numberValidator,
  objectValidator,
  arrayValidator,
} from '../../validate';
import { ValidationError } from '../../error';

export interface Exposure {
  id: string;
  name: string;
  type: ExposureType;
  createdAt: string;
  updatedAt: string;
  coverageAmount: number;
  perilOperator?: PerilOperator;
  perils: Peril[];
  claims: Claim[];
  attributes: ExposureAttributes;
  payoutTiers: PayoutTier[];
}

export enum ExposureType {
  Location = 'location',
}

export interface ExposureAttributes {
  latitude: number;
  longitude: number;
  timezone: string;
}

export interface PayoutTier {
  numberOfHours: number;
  payoutAmount: number;
}

function isExposureType(value: unknown): asserts value is ExposureType {
  switch (value) {
    case ExposureType.Location:
      return;
    default:
      throw new ValidationError(value, 'exposureType', [
        `error validating ExposureType: received ${JSON.stringify(value)}`,
      ]);
  }
}

export const stringToExposureType = (str: string): ExposureType | null => {
  if (typeof str !== 'string') return null;

  switch (str.toLowerCase()) {
    case 'location':
      return ExposureType.Location;
    default:
      return null;
  }
};

export function isExposureAttributes(
  value: unknown,
): asserts value is ExposureAttributes {
  objectValidator(value, 'exposureAttributes');

  const { latitude, longitude, timezone } = value;

  numberValidator(latitude, 'exposureAttributes.latitude');
  numberValidator(longitude, 'exposureAttributes.longitude');
  stringValidator(timezone, 'exposureAttributes.timezone', { nonEmpty: true });
}

export function isPayoutTier(value: unknown): asserts value is PayoutTier {
  objectValidator(value, 'payoutTier');

  const { numberOfHours, payoutAmount } = value;

  numberValidator(numberOfHours, 'payoutTier.numberOfHours');
  numberValidator(payoutAmount, 'payoutTier.payoutAmount');
}

export function isExposure(value: unknown): asserts value is Exposure {
  objectValidator(value, 'exposure');

  const {
    id,
    name,
    type,
    createdAt,
    updatedAt,
    coverageAmount,
    perilOperator,
    perils,
    claims,
    attributes,
    payoutTiers,
  } = value;

  const requiredStrings = [
    { name: 'exposure.id', value: id },
    { name: 'exposure.name', value: name },
    { name: 'exposure.createdAt', value: createdAt },
    { name: 'exposure.updatedAt', value: updatedAt },
  ];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const requiredNumbers = [
    { name: 'exposure.coverageAmount', value: coverageAmount },
  ];

  requiredNumbers.forEach((num) => {
    numberValidator(num.value, num.name);
  });

  isExposureType(type);
  isExposureAttributes(attributes);

  if (perilOperator !== undefined) {
    isPerilOperator(perilOperator);
  }

  arrayValidator(claims, 'exposure.claims', { typeguard: isClaim });
  arrayValidator(perils, 'exposure.perils', {
    typeguard: isPeril,
    nonEmpty: true,
  });
  arrayValidator(payoutTiers, 'exposure.payoutTiers', {
    typeguard: isPayoutTier,
    nonEmpty: true,
  });
}
