import { ValidationError } from '../../error';
import { numberValidator } from '../../validate';
import { objectValidator } from '../../validate';
import { stringValidator } from '../../validate';
import {
  CustomerSupportCode,
  isCustomerSupportCode,
} from './CustomerSupport.types';

export interface Loss {
  occurredAt: string;
  createdAt: string;
  amount: number;
  unit: LossUnit;
  forecastBasisTimestamp?: string;
  customerSupport?: CustomerSupportCode;
}

export enum LossUnit {
  Hour = 'hour',
  Second = 'second',
}

export const LossUnitDisplayMap: Record<LossUnit, string> = {
  [LossUnit.Hour]: 'hr',
  [LossUnit.Second]: 'sec',
};

export function isLossUnit(value: unknown): asserts value is LossUnit {
  switch (value) {
    case LossUnit.Hour:
    case LossUnit.Second:
      return;
    default:
      throw new ValidationError(value, 'lossUnit', [
        `error validating LossUnit: received ${JSON.stringify(value)}`,
      ]);
  }
}

export function isLoss(value: unknown): asserts value is Loss {
  objectValidator(value, 'loss');

  const {
    occurredAt,
    createdAt,
    amount,
    unit,
    forecastBasisTimestamp,
    customerSupport,
  } = value;

  const requiredStrings = [
    { name: 'loss.occurredAt', value: occurredAt },
    { name: 'loss.createdAt', value: createdAt },
  ];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const requiredNumbers = [{ name: 'loss.amount', value: amount }];

  requiredNumbers.forEach((num) => {
    numberValidator(num.value, num.name);
  });

  if (forecastBasisTimestamp === undefined && customerSupport === undefined) {
    throw new ValidationError(value, 'loss', [
      'loss requires one of forecastBasisTimestamp or customerSupport to be present, but both are undefined',
    ]);
  }

  if (forecastBasisTimestamp !== undefined) {
    stringValidator(forecastBasisTimestamp, 'loss.forecastBasisTimestamp', {
      nonEmpty: true,
    });
  }

  if (customerSupport !== undefined) {
    isCustomerSupportCode(customerSupport);
  }

  isLossUnit(unit);
}
